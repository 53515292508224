<template>
  <div>
    <img class='w-full'
      :src='dataUrl'>
  </div>
</template>

<script>
  export default {
    props: {
      value: String
    },

    data: () => ({
      dataUrl: ''
    }),

    watch: {
      value: {
        handler () {
          this.makeQrCode()
        },
        immediate: true
      }
    },

    methods: {
      getQrcodeGenerator () {
        if (window.getQrcodeGenerator) return window.getQrcodeGenerator

        window.getQrcodeGenerator = new Promise(resolve => {
          let script = document.createElement('script')

          script.src = 'https://cdn.jsdelivr.net/npm/' +
            'qrcode-generator@1.4.4/qrcode.min.js'
          script.addEventListener('load', () => {
            resolve(window.qrcode)
          })

          document.head.appendChild(script)
        })

        return window.getQrcodeGenerator
      },

      async makeQrCode () {
        if (!this.value) return

        let cellSize = 6
        let margin = 10
        let typeNumber = 4
        let errorCorrectionLevel = 'L'

        let qrcode = await this.getQrcodeGenerator()
        let qr = qrcode(typeNumber, errorCorrectionLevel)

        qr.addData(this.value)
        qr.make()
        this.dataUrl = qr.createDataURL(cellSize, margin)
      }
    }
  }
</script>
